.card {
  border: 0;
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  .card-header {
    background: transparent;
    border-bottom-color: #e5e4e4;
  }

  .card-title {
    margin: 0;
    font-size: 18px;
  }
}
