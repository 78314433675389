/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;

@include mat.core();

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import "variables";
@import "bootstrap/scss/bootstrap";
@import "styles/tables";
@import "styles/fonts";
@import "styles/dialog";
@import "styles/forms";
@import "styles/card";
@import "app/ui/forms/input/input";

$primary-palette: (
  50: #f6e4e5,
  100: #e8bcbf,
  200: #d99094,
  300: #ca6369,
  400: #be4148,
  500: #b32028,
  600: #ac1c24,
  700: #a3181e,
  800: #9a1318,
  900: #8b0b0f,
  A100: #ffbabb,
  A200: #ff8789,
  A400: #ff5457,
  A700: #ff3b3e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$accent-palette: (
  50: #eff0f0,
  100: #d8d8d9,
  200: #bebfc0,
  300: #a4a5a7,
  400: #919194,
  500: #7d7e81,
  600: #757679,
  700: #6a6b6e,
  800: #606164,
  900: #4d4e51,
  A100: #beccf9,
  A200: #8fa8f5,
  A400: #527dff,
  A700: #386aff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$app-primary: mat.define-palette($primary-palette);
$app-accent: mat.define-palette($primary-palette);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.legacy-core-theme($app-theme);
@include mat.legacy-tabs-theme($app-theme);
@include mat.legacy-snack-bar-theme($app-theme);
@include mat.legacy-slide-toggle-theme($app-theme);
@include mat.legacy-tooltip-theme($app-theme);
@include cdk.a11y-visually-hidden();

@include cdk.overlay();

body {
  background-color: $light-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-content {
  min-width: 1024px;
}

.page-container {
  max-width: $container-width;
  padding: 0 15px;
  width: 100%;
  margin: 32px auto;
}

button {
  &:focus {
    outline: none;
  }
}

.multiline-tooltip {
  white-space: pre-line;
  line-height: 1.2;
  font-size: 14px;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element, 
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background: none;
}
