.input-icon {
  position: relative;
  width: 100%;
  .icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 11px;
    color: #000000;
    opacity: .3;
  }
}

