@import '@angular/material/theming';
@import 'variables';

.ik-input-element {
  // Font needs to be inherited, because by default <input> has a system font.
  font: inherit;

  // The Material input should match whatever background it is above.

  // If background matches period background then so should the color for proper contrast
  color: currentColor;

  // By default, <input> has a padding, border, outline and a default width.
  outline: none;
  padding: 7px 13px;
  margin: 0;
  width: 100%;

  // Prevent textareas from being resized outside the form field.
  max-width: 100%;

  // Needed to make last line of the textarea line up with the baseline.
  vertical-align: bottom;

  // User agent stylesheets set the text-align of inputs explicitly to "start". Those can be
  // easily overwritten by targeting the input element using a simple CSS selector, but since
  // the text-align will be applied most of the time on the `ik-form-field` to also align the
  // placeholder, the alignment should be inherited here.
  text-align: inherit;

  // Undo the red box-shadow glow added by Firefox on invalid inputs.
  // See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-ui-invalid
  &:-moz-ui-invalid {
    box-shadow: none;
  }

  // Remove IE's default clear and reveal icons.
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  // Clear Safari's decorations for search fields.
  &,
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  // Also clear Safari's autofill icons. Note that this can't be in the
  // same selector as the IE ones, otherwise Safari will ignore it.
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-caps-lock-indicator,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  // Fixes an issue on iOS where the following input types will collapse to 1px,
  // if they're empty, because we've overridden their background color.
  // See: https://stackoverflow.com/questions/18381594/input-type-date-appearance-in-safari-on-ios
  &[type='date'],
  &[type='datetime'],
  &[type='datetime-local'],
  &[type='month'],
  &[type='week'],
  &[type='time'] {
    &::after {
      content: ' ';
      white-space: pre;
      width: 1px;
    }
  }

  // Reduce the size of the native buttons in a date/time input,
  // because they can increase the height of the input (see #13317).
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator,
  &::-webkit-clear-button {
    font-size: 0.75em;
  }

  border-radius: 3px;
  border: solid 1px rgb(218, 219, 222);
  background-color: rgb(255, 255, 255);
  transition: border 0.3s ease;

  &[readonly],
  &[disabled] {
    background: lighten(rgb(218, 219, 222), 10%);
  }

  .ik-form-field-invalid & {
    border-color: $primary;
  }

  &:focus {
    outline: none;
  }

  .ik-form-field.ik-focused & {
    border-color: $gray;
  }
}
