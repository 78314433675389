@import "variables";
.mat-dialog-container {
  border-radius: 2px!important;
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 0!important;
}
.mat-dialog-content {
  padding: 0!important;
  margin: 0!important;
}

.dialog-header {
  background: $primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  &-title {
    font-family: "Avenir LT Std 85 Heavy", sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: rgb(255, 255, 255);
    margin: 0;
    position: relative;
    bottom: -2px;
  }
  &-close {
    color: #ffffff;
    font-size: 0;
    transition: all ease 0.3s;
    &:hover {
      color: #ffffff;
      opacity: 0.7;
    }
  }
}

.dialog-tabs-header {
  padding-inline: 20px;
  border-bottom: 3px solid rgb(112 112 112 / 0.25);

  &-title {
    border: none;
    background: none;
    outline: none;
    font-family: "Avenir LT Std 55 Roman", "Source Sans Pro", sans-serif;
    font-size: 24px;
    color: #999;
    line-height: 1;
    padding: 20px;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;

    &.selected {
      font-weight: 600;
      color: black;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 7px;
        background-color: $primary;
      }
    }
  }
}

.dialog-content {
  padding: 15px;
}

.dialog-footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(112, 112, 112, 0.25);
  padding: 15px;
  &.end {
    justify-content: flex-end;
  }
  [ik-button] {
    &:not(:first-child) {
      margin-left: 7px;
    }
  }
}
