@import "variables";

[ik-table] {
  width: 100%;
  border-spacing: 0;
}

ik-header-row, [ik-header-row] {
  background: $gray;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

ik-header-cell, [ik-header-cell] {
  padding-left: 3px;
  padding-right: 3px;
}
ik-cell, [ik-cell] {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding-left: 3px;
  padding-right: 3px;
}

ik-row, ik-header-row, ik-footer-row, [ik-row], [ik-header-row], [ik-footer-row] {
  border: 0!important;
}

th.ik-header-cell, td.ik-cell, td.ik-footer-cell {
  border: 0 !important;
}

ik-row, [ik-row] {
  &:nth-child(even) {
    background: #f4f4f4;
  }
}
